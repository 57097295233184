.ReactCollapse--collapse {
  transition-property: height;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}
.roundAnim {
  transition-property: border, border-radius;
  transition-duration: 3500ms;
  transition-timing-function: ease-in;
}
.groupComm {
  font-weight: 600;
  color: #706e6e;
  background-color: aqua;
}
.collapseShadow {
  box-shadow: 4px 7px solid black;
}
