.outside-container {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  background: url("../../public/Path\ 26.svg");
  background-repeat: no-repeat;
  background-position: -150px center;
  background-size: cover;
}

/* section {
  display: flex;
  justify-content: center;
  align-items: center;
} */
:global(.ant-btn-primary):hover {
  background-color: lightgreen !important;
  border-color: lightgreen !important;
}
.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10rem;
  width: 100%;
}

.login-container {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-transform: uppercase;
  font-size: 2.7em;
  font-weight: bold;
  text-align: center;
}

.separator {
  width: 150px;
  height: 4px;
  background-color: green;
  margin-top: 0px;
  margin-bottom: 10px;
}
