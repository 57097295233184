.container {
  width: 100%;
  height: fit-content;
  margin: auto;
  margin-top: 50px;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 50px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.container:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.doctors h4 {
  text-align: center;
}

.list {
  list-style: none;
  margin-top: 50px;
}

.list li {
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}

.group {
  position: absolute;
  top: 24px;
  right: 55px;
}
.btn {
  background-color: red;
  color: white;
  font-weight: 500;
  margin-top: 30px;
}
.btn span {
  margin-right: 3px;
}

.add {
  background-color: green;
  color: white;
  font-weight: 500;
  margin-top: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  margin-left: 1080px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.add:hover {
  transform: translateY(5px);
}
.add span {
  font-size: 30px;
}
.delete {
  position: absolute;
  top: 0;
  right: 0;
}

.delete Button {
  background-color: red;
  margin: 20px;
  font-weight: 700;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.delete Button:hover {
  transform: translateY(10px);
}

.margin_left {
  margin-left: 10px;
}

p span {
  margin-left: 10px;
}

h3 span {
  margin-left: 10px;
}

.closeBtn {
  margin-left: 10px;
}

.checkBtn {
  margin-left: 10px;
}

.groupNameError {
  text-align: center;
  color: red;
  margin-bottom: 40px;
}

.groupForm {
  display: flex;
}

.groupName {
  color: #555;
  font-weight: bolder;
  font-size: 25px;
}

.groupId {
  color: #555;
  font-weight: bold;
  font-size: 15px;
}

.groupMember span {
  font-size: 15px;
  font-weight: bold;
  color: #555;
}
.groupPayoutHead {
  font-size: 15px;
  font-weight: bold;
  color: #555;
  margin: 0;
}
.groupPayoutCurrency {
  font-size: 20px;
  font-weight: bolder;
  color: black;
}

.groupPayoutMoney {
  color: white;
  font-weight: bolder;
  background-color: rgb(218, 87, 109);
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 0;
}

/* /////////////////// */
/* DOCTOR  */
.doctorBox {
  width: 100%;
  border-radius: 100px 100px;
  background-color: white;
  height: 80px;
  margin-right: 20px;
  display: flex;
  margin-bottom: 30px;
  /* justify-content: center; */
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 20px;
  border: 2px solid #333;
}
.image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.info {
  width: max-content;
}

.name {
  margin: 0;
  color: #555;
  width: 200px;
}
.department {
  margin: 0;
  color: #555;
  font-size: 10px;
}

.groupComm {
  margin-left: 20px;
  width: 60px;
  height: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #706e6e;
  text-align: center;
  border-radius: 100px 100px;
  background-color: aqua;
  position: relative;
}

.editGroupcomm {
  position: absolute;
  right: -10px;
  top: -15px;
  color: black;
  font-size: 15px;
}

.status {
  position: relative;
  padding-top: 5px;
  margin-left: 600px;
  min-width: 90px;
  text-align: center;
}
.statusText {
}
.groupStatus {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: aliceblue;
  /* border-radius: 100px 100px; */
  border-radius: 25px / 50px;
  padding: 5px 7px;
}

.rejected {
  background-color: red;
}

.pending {
  background-color: yellow;
  color: black;
}
.approved {
  background-color: green;
}
.flag {
  background-color: orange;
}

.editStatus {
  position: absolute;
  right: -15px;
  top: -15px;
  color: black;
}

.arrow {
  font-weight: bolder;
  font-size: 15px;
  width: 20px;
  filter: invert(80%);
}

.doctorInfo {
  width: 100%;
  background-color: white;
  height: fit-content;
  margin-right: 20px;

  border-radius: 30px;
  /* justify-content: center; */
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.doctorHeader {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.doctorHeader .arrow {
  margin-top: 20px;
  margin-left: 30px;
}

.doctorHeader .status {
  margin-left: 600px;
  margin-top: 20px;
}

.doctorHeader .image {
  margin-top: 20px;
}

.doctorHeader .name {
  margin-top: 20px;
}
.doctorHeader .groupComm {
  margin-top: 20px;
}

.doctorFooter {
  display: flex;
  align-items: center;
  padding: 10px;
}

.email {
  margin-left: 10px;
  font-size: 12px;
}

.emailIcon {
  font-size: 20px;
}

.email span {
  color: #555;
  margin-left: 10px;
  font-weight: bolder;
}

.payout {
  color: #333;
  margin-left: 20px;
}

.money {
  background-color: rgb(218, 87, 109);
  border-radius: 30px;
  width: fit-content;
  padding: 5px 8px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.currency {
  font-size: 15px;
  margin-right: 5px;
  font-weight: bolder;
}

.deleteDoctor {
  margin: auto;
  margin-right: 10px;
  color: red;
}

.Id {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Id span {
  font-weight: bold;
  color: #555;
  margin: 20px;
}

.hidden {
  display: none;
}
