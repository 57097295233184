.box {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.header {
  text-align: center;
}
.group_name {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 15px;
  background-color: orange;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
}

.doctors {
  margin-top: 30px;
}

.member {
  list-style: none;
  padding: 0;
}

.list {
  margin-bottom: 10px;
}

.circle {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.total {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.userIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn {
  margin-top: 20px;
}

.create {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 50px;

  padding: 10px;
  height: fit-content;
  width: fit-content;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 2px 3px 3px black;
  border: none;
}
.create:hover {
  transform: scale(1.25);
}
.error {
  color: red;
  text-align: center;
}
